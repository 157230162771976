@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;1,100;1,200&family=Raleway:ital,wght@0,100;0,200;1,100&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  html{
    font-family: 'Raleway',sans-serif;
  }
}